import { render, staticRenderFns } from "./send-proposal-email-interface.vue?vue&type=template&id=ad66671c&"
import script from "./send-proposal-email-interface.vue?vue&type=script&lang=js&"
export * from "./send-proposal-email-interface.vue?vue&type=script&lang=js&"
import style0 from "./send-proposal-email-interface.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VForm,VSwitch,VTextField})
